<template>
  <card-content
    :loader="isLoading"
    :items="imageList"
    type="image"
    :fileTypes="fileType"
  >
    <template slot="item-preview" slot-scope="{ url }">
      <img class="multimedia__thumbnail" :src="url"/>
    </template>
  </card-content>
</template>

<script>

export default {
  props: {
    databank: {
      required: true,
    },
  },
  components: {
    CardContent: () => import('@/components/settings/dataBank/MediaComponents/CardContent.vue'),
  },
  computed: {
    isLoading() {
      return this.databank.image === null;
    },
  },
  watch: {
    'databank.image': function setImg(newImg) {
      this.imageList = newImg || [];
    },
  },
  data() {
    return {
      imageList: [],
      fileType: 'image/*',
    };
  },
  mounted() {
    this.imageList = this.databank.image || [];
  },
};
</script>
